import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  GoBack,
} from "../../components"
import { getCurrentPath } from "../../redux/actions"
import ACTIONS from "../../redux/actions/actionTypes"

const Success = ({ location, getCurrentPath, selectedPlan }) => {
  const intl = useIntl()
  const [isValidPlan, setIsValidPlan] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  }, [getCurrentPath, location.pathname])

  useEffect(() => {
    return () => {
      dispatch({ type: ACTIONS.CLEAR_PURCHASE_RESULT })
    }
  })

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title={intl.formatMessage({ id: "sucess.title" })}
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                `}
              >
                <p
                  css={css`
                    max-width: 700px;
                    margin: 0 auto 2rem auto;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: var(--letter-spacing-sm);
                    line-height: 1.75;
                  `}
                >
                  {intl.formatMessage({ id: "sucess.string" })}
                </p>
                {selectedPlan && <PlanDisplayCard noChangeBtn />}
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin: 4rem auto 0 auto;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0"
                  onClick={() => navigate("/")}
                >
                  {intl.formatMessage({ id: "btn.return" })}
                </PrimaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem1" })}
            text={intl.formatMessage({ id: "redirect.text1" })}
            actionText={intl.formatMessage({ id: "redirect.action1" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan }) => ({
  selectedPlan,
})

export default connect(mapStateToProps, { getCurrentPath })(Success)
